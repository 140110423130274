import React from 'react';
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import {BrowserRouter as Router, Route, useLocation} from 'react-router-dom';
import BaseRoutes from "./routes";
import store from './redux/store';
import axios from 'axios';
import {Provider, useSelector} from 'react-redux';
import ScrollToTop from "./components/ScrollToTop";
import 'antd/dist/antd.min.css';
import './App.css';
import './App.less';
import {AnimatePresence} from "framer-motion";
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import ApiUnderConstruction from "./ApiUnderConstruction";
import CookieConsent from "./layouts/CookieConsent";
import Pusher from 'pusher-js';

const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
axios.defaults.baseURL = isLocalhost ? 'http://127.0.0.1:8000/api' : 'https://pattykiosk.com/api';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Accept'] = 'application/json';

const stripePromise = loadStripe('pk_live_51M3hJZBbNGYWNhGgBAp8FoPGePJ8Tke6EjpdCVcYqrL82CmoL1femjDZHFVLCT7SN0VVrYFMxmqz0QiRgqSMVoGa00z4dDOQ94');

const Base = () => {

    const under_construction = useSelector(state => state.settings.under_construction);
    const location = useLocation();
    const dashboardRoute = location.pathname.includes("/dashboard");
    const discartBg = location.pathname.endsWith("/about");

    return (
        under_construction?
            <ApiUnderConstruction />
        :
            <div className={!location.pathname.includes("/print/") ? "flex flex-col min-h-screen justify-between " : ''} >
                <Header/>
                <CookieConsent />
                <Elements stripe={stripePromise}>
                    <AnimatePresence>
                        <div
                            className={`${!global.config.underConstructions && !location.pathname.includes("/print/") ? `${discartBg?'':'bg-gray-100'} pt-16 mb-auto flex-1 flex flex-col brand-h-screen` : null} ${location.pathname.includes('/dashboard/')?'':'noselect'} `}
                            style={{backgroundColor: dashboardRoute?'#F4F5FA':''}}
                        >
                            <Route path="/" component={BaseRoutes}/>
                        </div>
                        <Footer/>
                    </AnimatePresence>
                </Elements>
            </div>

    );
}

export default Base;
