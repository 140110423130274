import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Space, Popconfirm, notification } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import BenefitForm from './BenefitForm';
import SevenShiftApi from '../../../../../http/SevenShiftApi';
import DefaultContainer from "../../../../../components/DefaultContainer"; // Adjust path accordingly

const BenefitsList = () => {
    const [benefits, setBenefits] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingBenefit, setEditingBenefit] = useState(null);

    const fetchBenefits = () => {
        setLoading(true);
        SevenShiftApi.getBenefits(
            (response) => {
                setBenefits(response.data.data);
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                notification.error({ message: "Failed to fetch benefits", description: error.response?.data?.message || error.message });
            }
        );
    };

    useEffect(() => {
        fetchBenefits();
    }, []);

    const showAddModal = () => {
        setEditingBenefit(null);
        setIsModalVisible(true);
    };

    const showEditModal = (benefit) => {
        setEditingBenefit(benefit);
        setIsModalVisible(true);
    };

    const handleDelete = (id) => {
        SevenShiftApi.deleteBenefit(
            id,
            (response) => {
                notification.success({ message: "Benefit deleted successfully" });
                fetchBenefits();
            },
            (error) => {
                notification.error({ message: "Failed to delete benefit", description: error.response?.data?.message || error.message });
            }
        );
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setEditingBenefit(null);
    };

    const handleFormSubmit = (values) => {
        if (editingBenefit) {
            // Update existing benefit
            SevenShiftApi.updateBenefit(
                editingBenefit.id,
                values,
                (response) => {
                    notification.success({ message: "Benefit updated successfully" });
                    handleModalClose();
                    fetchBenefits();
                },
                (error) => {
                    notification.error({ message: "Failed to update benefit", description: error.response?.data?.message || error.message });
                }
            );
        } else {
            // Create new benefit
            SevenShiftApi.createBenefit(
                values,
                (response) => {
                    notification.success({ message: "Benefit created successfully" });
                    handleModalClose();
                    fetchBenefits();
                },
                (error) => {
                    notification.error({ message: "Failed to create benefit", description: error.response?.data?.message || error.message });
                }
            );
        }
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Roles',
            dataIndex: 'roles',
            key: 'roles',
            render: (roles) => roles.map(role => role.name).join(', '),
        },
        {
            title: 'Exceptions',
            dataIndex: 'exception_users',
            key: 'exception_users',
            render: (users) => users.map(user => user.name).join(', '),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Space size="middle">
                    <Button icon={<EditOutlined />} onClick={() => showEditModal(record)}>
                        Edit
                    </Button>
                    <Popconfirm
                        title="Are you sure to delete this benefit?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button icon={<DeleteOutlined />} danger>
                            Delete
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <DefaultContainer>
            <Button type="primary" icon={<PlusOutlined />} onClick={showAddModal} style={{ marginBottom: 16 }}>
                Add Benefit
            </Button>
            <Table
                dataSource={benefits}
                columns={columns}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 10 }}
            />

            <Modal
                title={editingBenefit ? "Edit Benefit" : "Add Benefit"}
                visible={isModalVisible}
                footer={null}
                onCancel={handleModalClose}
                destroyOnClose
            >
                <BenefitForm
                    initialValues={editingBenefit}
                    onSubmit={handleFormSubmit}
                    onCancel={handleModalClose}
                />
            </Modal>
        </DefaultContainer>
    );
};

export default BenefitsList;
