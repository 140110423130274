// src/components/PayPeriods/PayPeriodForm.js

import React from 'react';
import { Form, Input, DatePicker, Select, Button } from 'antd';
import moment from 'moment';

const { Option } = Select;

const PayPeriodForm = ({ initialValues, onSubmit, onCancel }) => {
    const [form] = Form.useForm();

    const handleFinish = (values) => {
        const payload = {
            start_date: values.start_date.format('YYYY-MM-DD'),
            end_date: values.end_date.format('YYYY-MM-DD'),
            status: values.status,
        };
        onSubmit(payload);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={handleFinish}
            initialValues={{
                start_date: initialValues ? moment(initialValues.start_date) : null,
                end_date: initialValues ? moment(initialValues.end_date) : null,
                status: initialValues ? initialValues.status : 'open',
            }}
        >
            <Form.Item
                name="start_date"
                label="Start Date"
                rules={[{ required: true, message: 'Please select the start date!' }]}
            >
                <DatePicker style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
                name="end_date"
                label="End Date"
                rules={[
                    { required: true, message: 'Please select the end date!' },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            const startDate = getFieldValue('start_date');
                            if (!value || !startDate || value.isAfter(startDate)) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('End date must be after start date!'));
                        },
                    }),
                ]}
            >
                <DatePicker style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
                name="status"
                label="Status"
                rules={[{ required: true, message: 'Please select the status!' }]}
            >
                <Select>
                    <Option value="open">Open</Option>
                    <Option value="closed">Closed</Option>
                </Select>
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" style={{ marginRight: '8px' }}>
                    {initialValues ? 'Update Pay Period' : 'Create Pay Period'}
                </Button>
                <Button onClick={onCancel}>
                    Cancel
                </Button>
            </Form.Item>
        </Form>
    );
};

export default PayPeriodForm;
