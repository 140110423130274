import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Form, Input, Button, Select, notification, Spin} from 'antd';
import BranchInventory from '../../../../../http/BranchInventory';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../../components/DefaultContainer';

const {Option} = Select;

const CreateEndOfShiftReport = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const {branchId} = useParams();
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [incidentsReported, setIncidentsReported] = useState(false);
    const [noticesGiven, setNoticesGiven] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [noticeTypeRequired, setNoticeTypeRequired] = useState(false);
    const [selectedNoticeType, setSelectedNoticeType] = useState(null);

    useEffect(() => {
        // Load employees for branch
        BranchInventory.IndexBranchEmployees(branchId,
            response => {
                const data = response.data.data;
                setEmployees(data.employees || []);
                setLoading(false);
            },
            error => {
                notification.error({
                    message: 'Error',
                    description: 'Failed to load employees'
                });
                setLoading(false);
            });
    }, [branchId]);

    const onFinish = (values) => {
        setSaving(true);
        BranchInventory.StoreEndOfShiftReport(branchId, values, (response) => {
            notification.success({
                message: 'Success!',
                description: 'End of shift report created successfully.',
            });
            history.push(`/dashboard/operations/daily-logs/end-of-shift/reports/${branchId}/manage`);
            setSaving(false);
        }, (err) => {
            if (err.response && err.response.data && err.response.data.errors) {
                form.setFields(
                    Object.keys(err.response.data.errors).map((key) => ({
                        name: key,
                        errors: err.response.data.errors[key],
                    }))
                );
            } else {
                notification.error({
                    message: 'Error!',
                    description: 'Failed to create end of shift report.',
                });
            }
            setSaving(false);
        });
    };

    return (
        <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
            <DefaultContainer>
                <h2>Create End of Shift Report</h2>
                <Form form={form} onFinish={onFinish} layout="vertical">
                    <Form.Item name="date" label="Date" rules={[{required: true, message: 'Please select a date'}]}>
                        <Input type="date" />
                    </Form.Item>
                    <Form.Item name="shift" label="Shift" rules={[{required: true, message: 'Please select a shift'}]}>
                        <Select>
                            <Option value="Start of Day">Start of Day</Option>
                            <Option value="End of Day">End of Day</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="summary" label="Summary of Operations" rules={[{required: true, message: 'Please provide a summary of operations'}]}>
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    <Form.Item name="challenges" label="Challenges Encountered" rules={[{required: true, message: 'Please provide challenges encountered during the shift'}]}>
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    <Form.Item name="out_of_stock_materials" label="Materials Out of Stock" rules={[{required: true, message: 'Please list the materials that were out of stock'}]}>
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    <Form.Item name="unresolved_issues" label="Unresolved Issues">
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    <Form.Item name="incidents" label="Any Incidents to Report?" rules={[{required: true, message: 'Please specify if there were any incidents'}]}>
                        <Select onChange={(value) => setIncidentsReported(value === 'Yes')}>
                            <Option value="No">No</Option>
                            <Option value="Yes">Yes</Option>
                        </Select>
                    </Form.Item>
                    {incidentsReported && (
                        <Form.Item name="incident_description" label="Incident Description" rules={[{required: true, message: 'Please provide a description of the incident'}]}>
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    )}
                    <Form.Item
                        name="notices_given"
                        label="Were Any Notices or Warnings Given Out?"
                        rules={[{required: true, message: 'Please specify if any notices or warnings were given out'}]}
                    >
                        <Select onChange={(value) => {
                            const hasNotices = value === 'Yes';
                            setNoticesGiven(hasNotices);
                            setNoticeTypeRequired(hasNotices);
                        }}>
                            <Option value="No">No</Option>
                            <Option value="Yes">Yes</Option>
                        </Select>
                    </Form.Item>
                    {noticesGiven && (
                        <>
                            <Form.Item
                                name="notice_type"
                                label="Type"
                                rules={[{ required: noticeTypeRequired, message: 'Please select a type' }]}
                            >
                                <Select onChange={(val) => setSelectedNoticeType(val)}>
                                    <Option value="Notice">Notice</Option>
                                    <Option value="Warning">Warning</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="notice_user_id"
                                label="Select Employee"
                                rules={[{required: noticeTypeRequired, message: 'Please select an employee'}]}
                            >
                                <Select
                                    showSearch
                                    filterOption={(input, option) =>
                                        (option.children || '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    placeholder="Search Employee"
                                >
                                    {employees.map(emp => (
                                        <Option key={emp.id} value={emp.id}>
                                            {emp.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="notice_description" label="Notice/Warning Description" rules={[{required: noticeTypeRequired, message: 'Please provide a description'}]}>
                                <Input.TextArea rows={4} />
                            </Form.Item>
                        </>
                    )}
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={saving}>Submit Report</Button>
                    </Form.Item>
                </Form>
            </DefaultContainer>
        </SkeletonWrapper>
    );
};

export default CreateEndOfShiftReport;
