import React, { useEffect, useState } from 'react';
import { Form, Input, Select, InputNumber, Button, TimePicker, notification } from 'antd';
import moment from 'moment';
import SevenShiftApi from '../../../../../http/SevenShiftApi'; // Adjust path accordingly

const { Option } = Select;

const HourTypeForm = ({ initialValues, onSubmit, onCancel }) => {
    const [form] = Form.useForm();
    const [conditionType, setConditionType] = useState(null);
    const [rolesOptions, setRolesOptions] = useState([]);

    useEffect(() => {
        // Fetch roles for Supervisor condition
        SevenShiftApi.getRoles(
            (response) => {
                setRolesOptions(response.data.data);
            },
            (error) => {
                notification.error({ message: "Failed to fetch roles", description: error.response?.data?.message || error.message });
            }
        );

        if (initialValues) {
            // Determine condition type based on existing conditions
            const conditions = initialValues.conditions;
            let currentConditionType = 'regular'; // default

            if (conditions.threshold_hours !== undefined) {
                currentConditionType = 'overtime';
            } else if (conditions.start_time !== undefined) {
                currentConditionType = 'late_night';
            } else if (conditions.roles !== undefined) {
                currentConditionType = 'supervisor';
            }

            setConditionType(currentConditionType);

            // Set form values
            form.setFieldsValue({
                name: initialValues.name,
                priority: initialValues.priority,
                conditionType: currentConditionType,
                // Conditionally set fields
                ...(currentConditionType === 'overtime' && { threshold_hours: conditions.threshold_hours }),
                ...(currentConditionType === 'late_night' && {
                    start_time: moment(conditions.start_time, 'HH:mm'),
                    end_time: moment(conditions.end_time, 'HH:mm'),
                }),
                ...(currentConditionType === 'supervisor' && { roles: conditions.roles }),
            });
        } else {
            form.resetFields();
            setConditionType(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValues]);

    const handleConditionTypeChange = (value) => {
        setConditionType(value);
        // Reset condition fields when condition type changes
        form.resetFields(['threshold_hours', 'start_time', 'end_time', 'roles']);
    };

    const handleFinish = (values) => {
        let assembledConditions = {};

        switch (values.conditionType) {
            case 'overtime':
                assembledConditions = {
                    threshold_hours: values.threshold_hours,
                };
                break;
            case 'late_night':
                assembledConditions = {
                    start_time: values.start_time.format('HH:mm'),
                    end_time: values.end_time.format('HH:mm'),
                };
                break;
            case 'supervisor':
                assembledConditions = {
                    roles: values.roles,
                };
                break;
            case 'regular':
                // No additional conditions
                assembledConditions = {};
                break;
            default:
                assembledConditions = {};
        }

        const payload = {
            name: values.name,
            priority: values.priority,
            conditions: assembledConditions,
        };

        onSubmit(payload);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={handleFinish}
        >
            <Form.Item
                name="name"
                label="Hour Type Name"
                rules={[{ required: true, message: 'Please input the hour type name!' }]}
            >
                <Input placeholder="e.g., Overtime" />
            </Form.Item>

            <Form.Item
                name="priority"
                label="Priority"
                rules={[{ required: true, message: 'Please input the priority!' }]}
            >
                <InputNumber min={1} style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
                name="conditionType"
                label="Condition Type"
                rules={[{ required: true, message: 'Please select the condition type!' }]}
                initialValue={conditionType}
            >
                <Select onChange={handleConditionTypeChange} placeholder="Select condition type">
                    <Option value="overtime">Overtime</Option>
                    <Option value="late_night">Late Night</Option>
                    <Option value="supervisor">Supervisor</Option>
                    <Option value="regular">Regular</Option>
                </Select>
            </Form.Item>

            {/* Dynamic Condition Fields */}
            {conditionType === 'overtime' && (
                <Form.Item
                    name="threshold_hours"
                    label="Threshold Hours"
                    rules={[{ required: true, message: 'Please input the threshold hours!' }]}
                >
                    <InputNumber min={0} style={{ width: '100%' }} />
                </Form.Item>
            )}

            {conditionType === 'late_night' && (
                <>
                    <Form.Item
                        name="start_time"
                        label="Start Time"
                        rules={[{ required: true, message: 'Please select the start time!' }]}
                    >
                        <TimePicker format="HH:mm" style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item
                        name="end_time"
                        label="End Time"
                        rules={[{ required: true, message: 'Please select the end time!' }]}
                    >
                        <TimePicker format="HH:mm" style={{ width: '100%' }} />
                    </Form.Item>
                </>
            )}

            {conditionType === 'supervisor' && (
                <Form.Item
                    name="roles"
                    label="Supervisor Roles"
                    rules={[{ required: true, message: 'Please select at least one role!' }]}
                >
                    <Select mode="multiple" placeholder="Select roles">
                        {rolesOptions.map(role => (
                            <Option key={role.id} value={role.id}>{role.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
            )}

            {/* No additional fields for 'regular' */}

            <Form.Item>
                <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
                    {initialValues ? 'Update' : 'Create'}
                </Button>
                <Button onClick={onCancel}>
                    Cancel
                </Button>
            </Form.Item>
        </Form>
    );

};

export default HourTypeForm;
