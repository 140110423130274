import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Table, Button, Spin, notification, Typography } from 'antd';
import { ArrowLeftOutlined, DownloadOutlined } from '@ant-design/icons';
import SevenShiftApi from '../../../../../http/SevenShiftApi';
import DefaultContainer from "../../../../../components/DefaultContainer";

const { Title } = Typography;

const PayPeriodProcess = () => {
    const { payPeriodId } = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [payPeriod, setPayPeriod] = useState(null);
    const [reportData, setReportData] = useState([]);
    const [benefits, setBenefits] = useState([]);

    useEffect(() => {
        handleProcess(); // Immediately process upon load
    }, [payPeriodId]);

    const handleBack = () => {
        history.push('/dashboard/payrolls/pay_periods');
    };

    const handleProcess = () => {
        setLoading(true);
        SevenShiftApi.processPayPeriod(
            payPeriodId,
            (response) => {
                notification.success({ message: "Pay period processed successfully" });
                const data = response.data.data;
                setPayPeriod(data.payPeriod);
                setReportData(data.reportData);
                setBenefits(data.benefits || []);
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                notification.error({ message: "Failed to process pay period", description: error.response?.data?.message || error.message });
            }
        );
    };

    const handleExportCSV = () => {
        SevenShiftApi.exportPayPeriodCSV(
            payPeriodId,
            (response) => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `pay_period_${payPeriodId}_report.csv`);
                document.body.appendChild(link);
                link.click();
                link.remove();
                notification.success({ message: "CSV exported successfully" });
            },
            (error) => {
                notification.error({ message: "Failed to export CSV", description: error.response?.data?.message || error.message });
            }
        );
    };

    // Hour type columns:
    const baseColumns = [
        { title: 'User Name', dataIndex: 'user_name', key: 'user_name' },
        { title: 'Supervisor', dataIndex: 'Supervisor', key: 'Supervisor', render: val => val ?? 0 },
        { title: 'Overtime', dataIndex: 'Overtime', key: 'Overtime', render: val => val ?? 0 },
        { title: 'Late Night Hours', dataIndex: 'Late Night Hours', key: 'Late Night Hours', render: val => val ?? 0 },
        { title: 'Regular Hours', dataIndex: 'Regular Hours', key: 'Regular Hours', render: val => val ?? 0 },
    ];

    // Dynamically add one column per benefit:
    const benefitColumns = benefits.map(bName => ({
        title: bName,
        dataIndex: bName,
        key: bName,
        render: val => val ?? 0,
    }));

    const columns = [...baseColumns, ...benefitColumns];

    return (
        <DefaultContainer>
            <Button
                type="link"
                icon={<ArrowLeftOutlined />}
                onClick={handleBack}
                style={{ marginBottom: 16 }}
            >
                Back to Pay Periods
            </Button>
            <Title level={3}>Process Pay Period</Title>
            {payPeriod && (
                <div style={{ marginBottom: 24 }}>
                    <p><strong>Pay Period:</strong> {new Date(payPeriod.start_date).toLocaleDateString()} - {new Date(payPeriod.end_date).toLocaleDateString()}</p>
                    <p><strong>Status:</strong> {payPeriod.status.charAt(0).toUpperCase() + payPeriod.status.slice(1)}</p>
                    {payPeriod.status === 'closed' && reportData.length > 0 && (
                        <Button
                            type="primary"
                            onClick={handleExportCSV}
                            icon={<DownloadOutlined />}
                        >
                            Export CSV
                        </Button>
                    )}
                </div>
            )}
            {loading ? (
                <Spin tip="Processing...">
                    <Table columns={columns} dataSource={[]} />
                </Spin>
            ) : (
                <Table
                    columns={columns}
                    dataSource={reportData}
                    rowKey="user_id"
                    pagination={{ pageSize: 10 }}
                />
            )}
        </DefaultContainer>
    );
};

export default PayPeriodProcess;
