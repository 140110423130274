import React, { useState, useEffect, useRef } from 'react';
import { Checkbox, Form, Button, notification, Select } from "antd";
import Employees from "../../../../../http/Employees";
import FormContainer from "../../../../../components/FormContainer";
import FormItem from "../../../../../components/Form/FormItem";
import { useSelector } from "react-redux";

const { Option } = Select;

const roles_descriptions = {
    'department_manager': "Can manage departments",
    'branch_employee_manager': "Can manage branch employees",
    'discount_manager': "Can manage discounts",
    'discount_requester': "Can request discount presets",
    'discount_analyst': "Can generate reports for discounts",
    'survey_manager': "Can manage surveys",
    "branch_supervisor": "Is branch supervisor",
    "vendor": "Is vendor",
    'forecast_manager': "Can manage forecasts",
    'inventory_manager': "Can manage inventories",
    "inventory_team_lead": "is Inventory Team Lead",
    "inventory_assistant_general_manager": "is Inventory Assistant General Manager",
    "inventory_general_manager": "is Inventory General Manager",
    "payroll_manager": "Can manage Payrolls",
    "terminated_user": "Terminated User",
};

const defaultRoles = {
    employee: [],
    manager: ['department_manager', 'discount_analyst'],
    admin: ['department_manager', 'branch_employee_manager', 'discount_manager', 'discount_analyst']
};

const EmployeePermissions = ({ employee, reload, discard }) => {
    const user = useSelector(state => state.auth.user);
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const [roles, setRoles] = useState(
        employee.roles
            .map(role => role.name)
            .filter(roleName => roles_descriptions.hasOwnProperty(roleName)) || []
    );
    const [employeeType, setEmployeeType] = useState(employee.employee_type || 'employee');
    const initialLoad = useRef(true);

    useEffect(() => {
        if (initialLoad.current) {
            initialLoad.current = false;
        } else {
            setRoles(defaultRoles[employeeType]);
        }
    }, [employeeType]);

    const onSubmit = (values) => {
        setSaving(true);

        Employees.UpdatePermissions(employee.id, {
            employee_type: values.employee_type,
            roles: roles
        }, (response) => {
            notification.success({ message: 'Employee permissions updated successfully!' });
            reload();
            setSaving(false);
        }, (err) => {
            notification.error({ message: 'Failed to update employee permissions!' });
            setSaving(false);
        });
    };

    const handleRoleChange = (roleName) => {
        setRoles(prev => {
            if (prev.includes(roleName)) {
                return prev.filter(a => a !== roleName);
            } else {
                return [...prev, roleName];
            }
        });
    };

    return (
        <div>
            <Form
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                initialValues={{
                    employee_type: employeeType
                }}
            >
                <FormContainer>
                    <Form.Item
                        name="employee_type"
                        label="Employee Type"
                        rules={[{ required: true, message: 'Please select the employee type' }]}
                    >
                        <Select onChange={setEmployeeType}>
                            <Option value="employee">Employee</Option>
                            <Option value="manager">Manager</Option>
                            <Option value="admin">Admin</Option>
                        </Select>
                    </Form.Item>
                </FormContainer>

                <FormContainer>
                    <h2 className="text-xl uppercase font-bold">Roles</h2>
                    <ul>
                        {Object.keys(roles_descriptions).map(roleName => (
                            <li key={roleName}>
                                <Checkbox
                                    checked={roles.includes(roleName)}
                                    onChange={() => handleRoleChange(roleName)}
                                >
                                    {roles_descriptions[roleName]}
                                </Checkbox>
                            </li>
                        ))}
                    </ul>
                </FormContainer>

                <div className="w-full flex items-center">
                    <FormItem>
                        <Button type="primary" htmlType="submit" loading={saving} style={{ paddingLeft: "40px", paddingRight: "40px" }}>
                            Save
                        </Button>
                    </FormItem>

                    <FormItem style={{ marginLeft: "10px" }}>
                        <Button type="link" htmlType="button" disabled={saving} danger onClick={discard}>
                            Discard Changes
                        </Button>
                    </FormItem>
                </div>
            </Form>
        </div>
    );
};

export default EmployeePermissions;
