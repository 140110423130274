import React, { useEffect, useState } from 'react';
import { Form, Input, Select, InputNumber, Button, notification } from 'antd';
import SevenShiftApi from '../../../../../http/SevenShiftApi'; // Adjust the path accordingly

const { Option } = Select;

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const conditionTypes = [
    { label: 'Total Hours Worked', value: 'Total hours worked' },
    { label: 'Hours Worked After', value: 'Hours worked after' },
    { label: 'Has Hours Worked After', value: 'Has Hours Worked After' },
    { label: 'Hours Worked Before', value: 'Hours worked before' },
    { label: 'Has Hours Worked Before', value: 'Has Hours Worked Before' },
];

const BenefitForm = ({ initialValues, onSubmit, onCancel }) => {
    const [form] = Form.useForm();
    const [rolesOptions, setRolesOptions] = useState([]);
    const [usersOptions, setUsersOptions] = useState([]);

    useEffect(() => {
        // Fetch roles and users for dropdowns
        SevenShiftApi.getBenefitRoles(
            (response) => {
                setRolesOptions(response.data.data);
            },
            (error) => {
                notification.error({ message: "Failed to fetch roles", description: error.response?.data?.message || error.message });
            }
        );

        SevenShiftApi.getUsersForBenefitExceptions(
            (response) => {
                setUsersOptions(response.data.data);
            },
            (error) => {
                notification.error({ message: "Failed to fetch users", description: error.response?.data?.message || error.message });
            }
        );

        if (initialValues) {
            // Transform conditions from object to array for Form.List
            const conditionsArray = Object.entries(initialValues.conditions).map(([day, condition]) => ({
                day,
                condition_type: condition.condition_type,
                hour: condition.hour !== undefined ? condition.hour : null,
                multiplier: condition.multiplier,
            }));

            form.setFieldsValue({
                name: initialValues.name,
                roles: initialValues.roles.map(role => role.id),
                exceptions: initialValues.exception_users.map(user => user.id),
                conditions: conditionsArray,
            });
        } else {
            form.resetFields();
        }
    }, [initialValues, form]);

    const handleFinish = (values) => {
        const processedConditions = {};

        values.conditions.forEach(condition => {
            processedConditions[condition.day] = {
                condition_type: condition.condition_type,
                multiplier: condition.multiplier,
            };

            if (['Hours worked after', 'Hours worked before', 'Has Hours Worked After', 'Has Hours Worked Before'].includes(condition.condition_type)) {
                processedConditions[condition.day].hour = condition.hour;
            }
        });

        const payload = {
            name: values.name,
            roles: values.roles,
            exceptions: values.exceptions || [],
            conditions: Object.entries(processedConditions).map(([day, condition]) => ({
                day,
                condition_type: condition.condition_type,
                hour: condition.hour !== undefined ? condition.hour : null,
                multiplier: condition.multiplier,
            })),
        };

        onSubmit(payload);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={handleFinish}
        >
            <Form.Item
                name="name"
                label="Benefit Name"
                rules={[{ required: true, message: 'Please input the benefit name!' }]}
            >
                <Input placeholder="e.g., Transport Allowance" />
            </Form.Item>

            <Form.Item
                name="roles"
                label="Applicable Roles"
                rules={[{ required: true, message: 'Please select at least one role!' }]}
            >
                <Select mode="multiple" placeholder="Select roles">
                    {rolesOptions.map(role => (
                        <Option key={role.id} value={role.id}>{role.name}</Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                name="exceptions"
                label="Exceptions (Users)"
            >
                <Select mode="multiple" placeholder="Select users to exclude">
                    {usersOptions.map(user => (
                        <Option key={user.id} value={user.id}>{user.name}</Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.List name="conditions">
                {(fields, { add, remove }) => (
                    <div>
                        {fields.map(({ key, name, ...restField }) => (
                            <div key={key} style={{ border: '1px solid #d9d9d9', padding: '16px', marginBottom: '8px', borderRadius: '4px' }}>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'day']}
                                    label="Day"
                                    rules={[{ required: true, message: 'Please select a day!' }]}
                                >
                                    <Select placeholder="Select day">
                                        {daysOfWeek.map(day => (
                                            <Option key={day} value={day}>{day}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    {...restField}
                                    name={[name, 'condition_type']}
                                    label="Condition Type"
                                    rules={[{ required: true, message: 'Please select condition type!' }]}
                                >
                                    <Select placeholder="Select condition type">
                                        {conditionTypes.map(type => (
                                            <Option key={type.value} value={type.value}>{type.label}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                {/* Conditionally render 'Hour' field based on condition_type */}
                                <Form.Item
                                    shouldUpdate={(prevValues, currentValues) =>
                                        prevValues.conditions?.[name]?.condition_type !== currentValues.conditions?.[name]?.condition_type
                                    }
                                    noStyle
                                >
                                    {() => {
                                        const conditionType = form.getFieldValue(['conditions', name, 'condition_type']);
                                        if (['Hours worked after', 'Hours worked before', 'Has Hours Worked After', 'Has Hours Worked Before'].includes(conditionType)) {
                                            return (
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'hour']}
                                                    label="Hour"
                                                    rules={[{ required: true, message: 'Please select an hour!' }]}
                                                >
                                                    <Select placeholder="Select hour">
                                                        {Array.from({ length: 24 }, (_, i) => (
                                                            <Option key={i} value={i}>{i}:00</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            );
                                        }
                                        return null;
                                    }}
                                </Form.Item>

                                <Form.Item
                                    {...restField}
                                    name={[name, 'multiplier']}
                                    label="Multiplier"
                                    rules={[{ required: true, message: 'Please input the multiplier!' }]}
                                >
                                    <InputNumber min={0} step={0.1} style={{ width: '100%' }} />
                                </Form.Item>

                                <Button type="dashed" danger onClick={() => remove(name)} style={{ marginTop: '8px' }}>
                                    Remove Condition
                                </Button>
                            </div>
                        ))}

                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block>
                                Add Condition
                            </Button>
                        </Form.Item>
                    </div>
                )}
            </Form.List>

            <Form.Item>
                <Button type="primary" htmlType="submit" style={{ marginRight: '8px' }}>
                    {initialValues ? 'Update Benefit' : 'Create Benefit'}
                </Button>
                <Button onClick={onCancel}>
                    Cancel
                </Button>
            </Form.Item>
        </Form>
    );

};

export default BenefitForm;
