import React from 'react';
import {Collapse} from 'antd';
import {RiArrowDownSLine, RiArrowUpSLine} from '@remixicon/react';
import styled from 'styled-components';
import {useSelector} from "react-redux";

const {Panel} = Collapse;

// Styled components for the About Section
const AboutSection = styled.section`
  background-color: white;
  padding: 2rem 1rem;
  max-width: 768px;
  margin: 0 auto;

  h2 {
    margin-bottom: 1rem;
    font-size: 2.5rem;
    font-weight: 800;
    text-align: center;
    text-transform: uppercase;
    color: #1a202c; /* Tailwind's text-gray-900 equivalent */
  }
`;

const StyledCollapse = styled(Collapse)`
  background: none;
  border: none;

  .ant-collapse-item {
    border-bottom: 1px solid #e5e7eb; /* Tailwind's border-gray-200 equivalent */
    margin-bottom: 0.5rem;

    &:last-child {
      border-bottom: none;
    }
  }

  .ant-collapse-header {
    font-size: 2.00rem;
    font-weight: bold;
    color: #1a202c; /* Tailwind's text-gray-900 equivalent */
    padding: 0.75rem 1rem;
    background: none; /* Tailwind's bg-gray-50 equivalent */
    border-radius: 0.5rem;
    transition: background-color 0.2s;
    font-family: PattySlaps;

    &:hover {
      background: #e5e7eb; /* Tailwind's bg-gray-200 equivalent */
    }
  }

    .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
      font-size: 40px !important;
    }
    

  .ant-collapse-content-box {
    padding: 1rem;
    font-size: 1rem;
    color: #4b5563; /* Tailwind's text-gray-600 equivalent */
  }

  .ant-collapse-expand-icon {
    margin-right: 0.5rem;
  }
`;

const About = () => {
    const lang = useSelector(state => state.settings.lang);
    const customExpandIcon = ({isActive}) => isActive ? <RiArrowUpSLine size={30}/> : <RiArrowDownSLine size={30}/>;

    return (
        <div className={"h-full"}>
            <section className="bg-white h-full">
                <div className="py-6 lg:py-16 px-4 mx-auto max-w-screen-md">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white uppercase patty hidden">
                        About Us
                    </h2>
                    {lang == 'fr' ?
                        <StyledCollapse
                            bordered={false}
                            expandIconPosition="right"
                            expandIcon={customExpandIcon}
                        >
                            <Panel header="Alors, Pourquoi les Burgers?" key="1">
                                <p className={"pl-5 font-bold"}>
                                    Pour être honnête, le burger n'était qu'un prétexte. On n’est pas là pour être “un autre resto de burgers”. On est là pour bousculer le fast casual avec quelque chose de vraiment frais (au sens propre du terme). Chaque jour, on prépare les pains, mélange les sauces, et grille les galettes comme si c’était notre manière de parler d’amour. Pourquoi? Parce qu’on croit qu’un burger peut être bien plus qu’un simple burger.
                                </p>
                            </Panel>
                            <Panel header="Notre Recette : La Fraîcheur dans Chaque Bouchée" key="2">
                                <p className={"pl-5 font-bold"}>
                                    Tout ce qu’on sert est fait maison, à partir de rien, chaque jour. Nos sauces, nos pains, nos galettes – ce ne sont pas juste des ingrédients, mais le cœur de saveur qui donne vie à chaque burger. On travaille avec des fournisseurs locaux qui partagent notre passion pour la qualité, pour que chaque bouchée soit aussi fraîche qu’inoubliable.
                                </p>
                            </Panel>
                            <Panel header="La Grande Vision" key="3">
                                <p className={"pl-5 font-bold"}>
                                    On voulait créer un endroit qui se sent comme chez soi, avec des saveurs qui parlent d’elles-mêmes. Mais on ne s’arrête pas là. Notre objectif est de devenir la plus grande chaîne de burgers fast casual au Canada, en étendant notre activité à l’échelle nationale pour offrir cette expérience audacieuse aux communautés de tout le pays. Dès que vous entrez, ce n'est plus seulement un repas – c’est le partage de quelque chose de vrai, dans un espace aussi vibrant
                                    et
                                    savoureux que nos burgers.
                                </p>
                            </Panel>
                            <Panel header="Fraîcheur, Communauté et un Soupçon de Fun" key="4">
                                <p className={"pl-5 font-bold"}>
                                    Patty Slaps, c’est plus que de bons plats – c’est un amour pour le partage. Que vous soyez là pour les saveurs, l’ambiance, ou simplement pour voir ce qui fait parler, vous faites partie d’une communauté qui valorise le goût, la qualité, et l’originalité. Parce que pour nous, le burger n’était qu’un prétexte. La vraie histoire, c’est tout ce qui se passe après.
                                </p>
                            </Panel>
                        </StyledCollapse>
                        :
                        <StyledCollapse
                            bordered={false}
                            expandIconPosition="right"
                            expandIcon={customExpandIcon}
                        >
                            <Panel header="SO, WHY BURGERS?" key="1">
                                <p className={"pl-5 font-bold"}>
                                    To be honest, the burger was just an excuse. We're not here to be
                                    "just another burger place." We're here to shake up the fast-casual
                                    scene with something fresh (literally). Every day, we’re rolling out
                                    buns, stirring up sauces, and grilling patties like it’s a love
                                    language. Why? Because we believe a burger can be more than just a
                                    burger.
                                </p>
                            </Panel>
                            <Panel header="OUR RECIPE: FRESHNESS YOU CAN TASTE" key="2">
                                <p className={"pl-5 font-bold"}>
                                    At Patty Slaps, we take pride in our culinary artistry. Our
                                    ingredients are meticulously sourced and handpicked to ensure only
                                    the freshest and finest elements grace your plate. Our blend of
                                    chuck and brisket forms the foundation of our delectable patties,
                                    and every element – from our locally-supplied pretzel buns to our
                                    house-made sauces – is a testament to our commitment to excellence.
                                </p>
                            </Panel>
                            <Panel header="WHAT'S THE BIG IDEA?" key="3">
                                <p className={"pl-5 font-bold"}>
                                    Beyond crafting exceptional burgers, we're dedicated to creating an
                                    atmosphere that fosters community and connection. Our social tables
                                    are a testament to our belief that good food brings people
                                    together. Whether you're enjoying our handcrafted creations with
                                    friends, family, or fellow burger enthusiasts, you're in for an
                                    experience that transcends the plate.
                                </p>
                            </Panel>
                            <Panel header="FRESHNESS, COMMUNITY, AND A DASH OF FUN" key="4">
                                <p className={"pl-5 font-bold"}>
                                    We're not just about food; we're about innovation. Our in-house
                                    artists pour their creativity into every graphic and animation,
                                    giving our brand a unique visual identity. Our cutting-edge
                                    technology, from our convenient self-serve kiosks to our
                                    user-friendly app, ensures that your experience is seamless and
                                    hassle-free. And we're not stopping here – stay tuned for exciting
                                    seasonal offerings and creative collaborations that push the
                                    boundaries of burger perfection.
                                </p>
                            </Panel>
                        </StyledCollapse>
                    }
                </div>
            </section>
        </div>
    );
};

export default About;
