import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { InputNumber, Button, Switch, notification } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { motion, AnimatePresence } from 'framer-motion';
import BranchInventory from "../../../../../http/BranchInventory";
import SkeletonWrapper from "../../../../../components/skeletons/SkeletonWrapper";
import GenericSkeleton from "../../../../../components/skeletons/GenericSkeleton";
import DefaultContainer from "../../../../../components/DefaultContainer";
import BranchInventoryGroup from "../../../../../http/BranchInventoryGroup";

const InventoryEndOfDay = () => {
    const { branchId } = useParams();
    const { confirmation } = useParams();
    const isConfirmed = confirmation === "1" || confirmation === "true";

    const [branchInventoryGroups, setBranchInventoryGroups] = useState([]);
    const [branch, setBranch] = useState(null);
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [quantities, setQuantities] = useState({});
    const [showActualWeight, setShowActualWeight] = useState({});
    const [workingDate, setWorkingDate] = useState('');
    const history = useHistory();

    useEffect(() => {
    }, [isConfirmed]);

    useEffect(() => {
        setLoading(true);
        BranchInventoryGroup.GetBranchInventoryGroups(branchId, isConfirmed, (response) => {
            const { branch_inventory_groups, daily_records, branch, working_date } = response.data.data;
            setBranchInventoryGroups(branch_inventory_groups);
            setBranch(branch);
            setWorkingDate(working_date);

            const initialQuantities = {};
            const initialShowActualWeight = {};
            branch_inventory_groups.forEach(branch_inventory_group => {
                initialQuantities[branch_inventory_group.id] = {};
                initialShowActualWeight[branch_inventory_group.id] = true; // default true

                const record = daily_records.find(record => record.branch_inventory_group_id === branch_inventory_group.id);
                if (record) {
                    let containers = [];

                    if (isConfirmed) {
                        containers = record.confirmed_containers;
                    } else {
                        containers = record.not_confirmed_containers;
                    }

                    containers.forEach(container => {
                        initialQuantities[branch_inventory_group.id][container.branch_inventory_group_container_id] = {
                            full: container.quantity,
                            // remainings is an array of numbers
                            remainings: container.remainings ? container.remainings : []
                        };
                    });
                }
            });
            setQuantities(initialQuantities);
            setShowActualWeight(initialShowActualWeight);
            setLoading(false);
        }, (err) => {
            setLoading(false);
            notification['error']({
                message: 'Error!',
                description: err.response?.data?.message,
            });
            history.push("/dashboard/operations/daily-logs/inventory/end-of-day/branch");
        });
    }, [branchId]);

    const handleQuantityChange = (branchInventoryGroupId, containerId, value) => {
        setQuantities(prevQuantities => {
            const newQuantities = { ...prevQuantities };
            if (!newQuantities[branchInventoryGroupId]) {
                newQuantities[branchInventoryGroupId] = {};
            }
            if (!newQuantities[branchInventoryGroupId][containerId]) {
                newQuantities[branchInventoryGroupId][containerId] = { full: 0, remainings: [] };
            }

            newQuantities[branchInventoryGroupId][containerId].full = value;
            return newQuantities;
        });
    };

    const handleRemainingWeightChange = (branchInventoryGroupId, containerId, index, value) => {
        setQuantities(prev => {
            const newQuantities = { ...prev };
            newQuantities[branchInventoryGroupId][containerId].remainings[index] = value;
            return newQuantities;
        });
    };

    const addRemainingContainer = (branchInventoryGroupId, containerId) => {
        setQuantities(prev => {
            const newQuantities = { ...prev };
            if (!newQuantities[branchInventoryGroupId][containerId].remainings) {
                newQuantities[branchInventoryGroupId][containerId].remainings = [];
            }
            newQuantities[branchInventoryGroupId][containerId].remainings.push(0);
            return newQuantities;
        });
    };

    const removeRemainingContainer = (branchInventoryGroupId, containerId, index) => {
        setQuantities(prev => {
            const newQuantities = { ...prev };
            newQuantities[branchInventoryGroupId][containerId].remainings.splice(index, 1);
            return newQuantities;
        });
    };

    const handleWeightToggle = (inventoryId) => {
        setShowActualWeight(prevState => ({
            ...prevState,
            [inventoryId]: !prevState[inventoryId]
        }));
    };

    const incrementValue = (branchInventoryGroupId, containerId) => {
        const currentValue = quantities[branchInventoryGroupId]?.[containerId]?.full || 0;
        handleQuantityChange(branchInventoryGroupId, containerId, currentValue + 1);
    };

    const decrementValue = (branchInventoryGroupId, containerId) => {
        const currentValue = quantities[branchInventoryGroupId]?.[containerId]?.full || 0;
        handleQuantityChange(branchInventoryGroupId, containerId, Math.max(currentValue - 1, 0));
    };

    const handleSubmit = () => {
        setSubmitting(true);
        BranchInventoryGroup.SubmitEndOfDay(branchId, quantities, isConfirmed, (response) => {
            setSubmitting(false);
            notification['success']({
                message: 'Success!',
                description: response.data.message,
            });
            if (isConfirmed)
                history.push("/dashboard/operations/daily-logs/inventory/start-of-day/branch");
            else
                history.push("/dashboard/operations/daily-logs/inventory/end-of-day/branch");
        }, (err) => {
            setSubmitting(false);
            notification['error']({
                message: 'Error!',
                description: err.response?.data?.message,
            });
        });
    };

    const calculateActualWeight = (branchInventoryGroup) => {
        let totalWeight = 0;
        const inventoryQuantities = quantities[branchInventoryGroup.id] || {};

        branchInventoryGroup.branch_inventory_group_containers.forEach(container => {
            const containerQuantities = inventoryQuantities[container.id] || { full: 0, remainings: [] };
            const fullContainersWeight = containerQuantities.full * (container.full_container_weight - container.container.empty_weight);
            let remainingsWeight = 0;
            containerQuantities.remainings.forEach(rWeight => {
                if (rWeight > container.container.empty_weight)
                    remainingsWeight += (rWeight - container.container.empty_weight);
            });
            totalWeight += fullContainersWeight + remainingsWeight;
        });
        return totalWeight;
    };

    const getContainerWeight = (container, fullContainers, percentageFilled, showActual) => {
        const fullWt = showActual
            ? container.full_container_weight
            : (container.full_container_weight - container.container.empty_weight);

        return fullContainers * fullWt + (percentageFilled / 100 * fullWt);
    };

    const renderContainerPresentation = (branchInventoryGroupId, container, branchInventoryGroup) => {
        const containerQuantities = quantities[branchInventoryGroupId]?.[container.id] || { full: 0, remainings: [] };
        const totalFullContainers = containerQuantities.full || 0;
        const showActual = showActualWeight[branchInventoryGroupId] || false;

        // sum remainings for display
        // Instead of percentage, we now have multiple partial containers.
        // We'll display each partial container as a separate item.
        return (
            <AnimatePresence>
                {[...Array(totalFullContainers)].map((_, index) => (
                    <motion.div
                        key={index}
                        className="relative my-2"
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, x: -20 }}
                        style={{ width: '60px', marginRight: '10px' }}
                    >
                        <img
                            src={container.container.image_url}
                            alt={container.container.name}
                            style={{ width: '60px' }}
                        />
                        <div className="absolute bottom-0 left-0 w-full text-center text-white bg-black bg-opacity-50 whitespace-nowrap">
                            {getContainerWeight(container, 1, 0, showActual).toFixed(0)}{branchInventoryGroup.unit_type}
                        </div>
                    </motion.div>
                ))}

                {containerQuantities.remainings.map((rWeight, idx) => (
                    <motion.div
                        key={`weight-${container.id}-${idx}`}
                        className="relative my-2"
                        style={{ width: '60px', marginRight: '10px' }}
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, x: -20 }}
                    >
                        <img src={container.container.image_url} alt={container.container.name} style={{ width: '60px' }} />
                        <div className="absolute bottom-0 left-0 w-full" style={{ height: `100%`, backgroundColor: 'rgba(0, 255, 255, 0.2)' }} />
                        <div className="absolute bottom-0 left-0 w-full text-center text-white bg-black bg-opacity-50">
                            {rWeight.toFixed(0)}{branchInventoryGroup.unit_type}
                        </div>
                    </motion.div>
                ))}
            </AnimatePresence>
        );
    };

    return (
        <DefaultContainer className="mx-auto max-w-8xl" append>
            <div className="flex flex-col py-3 w-full select-none">
                {branch &&
                    <h1 className="text-2xl font-bold mb-4 uppercase patty upper border-b border-gray-200">
                        {isConfirmed ? 'Start' : 'End'} of Day Inventory - <span>{branch.name} ({workingDate})</span>
                    </h1>
                }
                <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                    {branchInventoryGroups && branchInventoryGroups.map(branchInventoryGroup => (
                        <div key={branchInventoryGroup.id} className={"p-2 pt-0 border bg-white rounded-lg shadow-lg mb-4 border-gray-300"}>
                            <div className={"font-bold text-3xl patty pt-2 border-b border-gray-300 flex flex-row items-center justify-between "}>
                                {branchInventoryGroup.name} (Total: {calculateActualWeight(branchInventoryGroup).toFixed(0)} {branchInventoryGroup.unit_type})
                                <div className={"ml-4 text-lg flex flex-row items-center hidden"}>
                                    <div className={"mr-2 -mb-1"}>
                                        {showActualWeight[branchInventoryGroup.id] ? "Ingredient with container weight" : "Ingredient weight only"}
                                    </div>
                                    <Switch checked={showActualWeight[branchInventoryGroup.id] || false} onChange={() => handleWeightToggle(branchInventoryGroup.id)} />
                                </div>
                            </div>
                            {branchInventoryGroup.branch_inventory_group_containers.map(container => (
                                <div key={container.id} className={"flex flex-col lg:flex-row border-b border-gray-300 py-1 lg:items-center"}>
                                    <div className={"flex flex-row justify-between lg:justify-start"}>
                                        <div className={"flex flex-row"}>
                                            <img src={container.container.image_url} alt={container.container.name} style={{ width: '60px', marginRight: '10px' }} />
                                            <span className={"w-48 font-bold"}>{container.container.name}</span>
                                        </div>
                                        <div className={"flex flex-col"}>
                                            <div className={"pt-2 flex items-center pl-2"}>
                                                <Button icon={<MinusOutlined />} onClick={() => decrementValue(branchInventoryGroup.id, container.id)} />
                                                <InputNumber
                                                    min={0}
                                                    value={quantities[branchInventoryGroup.id]?.[container.id]?.full || 0}
                                                    onChange={(value) => handleQuantityChange(branchInventoryGroup.id, container.id, value)}
                                                    style={{ width: '60px', marginLeft: '10px', marginRight: '10px' }}
                                                    type={"number"}
                                                />
                                                <Button icon={<PlusOutlined />} onClick={() => incrementValue(branchInventoryGroup.id, container.id)} />
                                            </div>

                                            {/* Multiple remainings inputs */}
                                            {quantities[branchInventoryGroup.id]?.[container.id]?.remainings?.map((weightVal, idx) => (
                                                <div key={idx} className="flex items-center my-2">
                                                    <InputNumber
                                                        min={0}
                                                        value={weightVal}
                                                        onChange={(value) => handleRemainingWeightChange(branchInventoryGroup.id, container.id, idx, value)}
                                                        style={{ width: '150px', marginRight: '10px' }}
                                                        placeholder="Partial Container Weight"
                                                        type={"number"}
                                                    />
                                                    <Button danger onClick={() => removeRemainingContainer(branchInventoryGroup.id, container.id, idx)}>Remove</Button>
                                                </div>
                                            ))}
                                            <Button type="dashed" style={{ marginTop: '10px' }} onClick={() => addRemainingContainer(branchInventoryGroup.id, container.id)}>Add Partial Container</Button>
                                        </div>
                                    </div>
                                    <div className={"bg-gray-200 px-2 lg:ml-5 mt-2 lg:mt-0 flex flex-row justify-center lg:justify-start rounded-lg overflow-hidden overflow-x-auto"}>
                                        {renderContainerPresentation(branchInventoryGroup.id, container, branchInventoryGroup)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                    <Button type="primary" className={"primary-button"} onClick={handleSubmit} loading={submitting}>Submit</Button>
                </SkeletonWrapper>
            </div>
        </DefaultContainer>
    );
};

export default InventoryEndOfDay;
