import React, { useState } from 'react';
import DefaultContainer from "../../../../components/DefaultContainer";
import { Button, Typography, notification } from "antd";
import SevenShiftApi from "../../../../http/SevenShiftApi";
import { faTriangleExclamation, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom"; // Adjust path if necessary

const { Text, Title } = Typography;

const PayrollsSevenShifts = () => {
    const [loading, setLoading] = useState(false);
    const [syncResult, setSyncResult] = useState(null);

    const handleSync = () => {
        setLoading(true);
        setSyncResult(null); // Clear previous results
        SevenShiftApi.sync(
            (response) => {
                setLoading(false);
                setSyncResult(response.data.data);
                notification.success({ message: "Sync completed successfully." });
            },
            (error) => {
                setLoading(false);
                notification.error({ message: "Sync failed", description: error.message || "An unexpected error occurred." });
            }
        );
    };

    return (
        <DefaultContainer>
            <Title level={3}>7shifts Synchronization</Title>
            <Text style={{ marginBottom: 16 }}>
                Sync users, roles, and time punches with 7shifts to ensure your payroll data is up-to-date.
            </Text>
            <Button type="primary" loading={loading} onClick={handleSync} style={{ marginBottom: 24 }}>
                {loading ? 'Syncing...' : 'Start Sync'}
            </Button>

            {syncResult && (
                <div style={{ marginTop: 24 }}>
                    <Title level={4}>Sync Report</Title>

                    {/* Users Sync Report */}
                    <div style={{ marginBottom: 24 }}>
                        <Title level={5}>Users</Title>
                        <div style={{ marginBottom: 12 }}>
                            <Text strong>Total Users Imported:</Text> {syncResult.user_sync.original.data.total_users_imported}
                            {syncResult.user_sync.original.data.imported_users.length > 0 && (
                                <ul style={{ paddingLeft: 20 }}>
                                    {syncResult.user_sync.imported_users.map((user) => (
                                        <li key={user.id} className={`${user.status === 0 ? 'opacity-20' : ''}`}>
                                            {user.status === 0 && (
                                                <FontAwesomeIcon icon={faExclamationCircle} className="text-red-700 mr-2" aria-hidden="true" />
                                            )}
                                            <b>
                                                <Link to={`/dashboard/hr/employees/${user.id}/edit`}>{user.name}</Link>
                                            </b> ({user.email})
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        <div style={{ marginBottom: 12 }}>
                            <Text strong>Punch IDs Updated:</Text> {syncResult.user_sync.original.data.punch_ids_updated}
                            {syncResult.user_sync.original.data.updated_punch_ids.length > 0 && (
                                <ul style={{ paddingLeft: 20 }}>
                                    {syncResult.user_sync.original.data.updated_punch_ids.map((user) => (
                                        <li key={user.id} className={`${user.status === 0 ? 'opacity-50' : ''}`}>
                                            <b>{user.name}</b> ({user.email})
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        <div style={{ marginBottom: 12 }}>
                            <Text strong>Users Disabled:</Text> {syncResult.user_sync.original.data.users_disabled}
                            {syncResult.user_sync.original.data.disabled_users.length > 0 && (
                                <ul style={{ paddingLeft: 20 }}>
                                    {syncResult.user_sync.original.data.disabled_users.map((user) => (
                                        <li key={user.id}>
                                            <b>{user.name}</b> ({user.email})
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>

                    {/* Roles Sync Report */}
                    <div style={{ marginBottom: 24 }}>
                        <Title level={5}>Roles</Title>
                        <div style={{ marginBottom: 12 }}>
                            <Text strong>Total Roles Imported or Updated:</Text> {syncResult.role_sync.total_roles_imported_or_updated}
                        </div>

                        {syncResult.role_sync.imported_roles.length > 0 && (
                            <div style={{ marginBottom: 12 }}>
                                <Text strong>Imported Roles:</Text>
                                <ul style={{ paddingLeft: 20 }}>
                                    {syncResult.role_sync.imported_roles.map((role) => (
                                        <li key={role.id}>
                                            <b>{role.name}</b> (ID: {role.sevenshift_role_id})
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {syncResult.role_sync.updated_roles.length > 0 && (
                            <div style={{ marginBottom: 12 }}>
                                <Text strong>Updated Roles:</Text>
                                <ul style={{ paddingLeft: 20 }}>
                                    {syncResult.role_sync.updated_roles.map((role) => (
                                        <li key={role.id}>
                                            <b>{role.name}</b> (ID: {role.sevenshift_role_id})
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>

                    {/* Time Punches Sync Report */}
                    <div style={{ marginBottom: 24 }}>
                        <Title level={5}>Time Punches</Title>
                        <div style={{ marginBottom: 12 }}>
                            <Text strong>Total Time Punches Imported:</Text> {syncResult.time_punch_sync.total_time_punches_imported}
                            {syncResult.time_punch_sync.imported_time_punches.length > 0 && (
                                <ul style={{ paddingLeft: 20 }}>
                                    {syncResult.time_punch_sync.imported_time_punches.map((tp) => (
                                        <li key={tp.id}>
                                            Shift ID: {tp.shift_id}, User ID: {tp.sevenshift_user_id}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        <div style={{ marginBottom: 12 }}>
                            <Text strong>Time Punches Updated:</Text> {syncResult.time_punch_sync.updated_time_punches.length}
                            {syncResult.time_punch_sync.updated_time_punches.length > 0 && (
                                <ul style={{ paddingLeft: 20 }}>
                                    {syncResult.time_punch_sync.updated_time_punches.map((tp) => (
                                        <li key={tp.id}>
                                            Shift ID: {tp.shift_id}, User ID: {tp.sevenshift_user_id}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        <div style={{ marginBottom: 12 }}>
                            <Text strong>Breaks Imported:</Text> {syncResult.time_punch_sync.imported_breaks.length}
                            {syncResult.time_punch_sync.imported_breaks.length > 0 && (
                                <ul style={{ paddingLeft: 20 }}>
                                    {syncResult.time_punch_sync.imported_breaks.map((brk) => (
                                        <li key={brk.id}>
                                            Break ID: {brk.id}, Time Punch ID: {brk.time_punch_id}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        <div style={{ marginBottom: 12 }}>
                            <Text strong>Breaks Updated:</Text> {syncResult.time_punch_sync.updated_breaks.length}
                            {syncResult.time_punch_sync.updated_breaks.length > 0 && (
                                <ul style={{ paddingLeft: 20 }}>
                                    {syncResult.time_punch_sync.updated_breaks.map((brk) => (
                                        <li key={brk.id}>
                                            Break ID: {brk.id}, Time Punch ID: {brk.time_punch_id}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </DefaultContainer>
    );
};

export default PayrollsSevenShifts;
