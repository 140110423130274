import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Space, Popconfirm, notification } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import HourTypeForm from './HourTypeForm';
import SevenShiftApi from '../../../../../http/SevenShiftApi';
import DefaultContainer from "../../../../../components/DefaultContainer"; // Adjust path accordingly

const HourTypesList = () => {
    const [hourTypes, setHourTypes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingHourType, setEditingHourType] = useState(null);

    const fetchHourTypes = () => {
        setLoading(true);
        SevenShiftApi.getHourTypes(
            (response) => {
                setHourTypes(response.data.data);
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                notification.error({ message: "Failed to fetch hour types", description: error.response?.data?.message || error.message });
            }
        );
    };

    useEffect(() => {
        fetchHourTypes();
    }, []);

    const showAddModal = () => {
        setEditingHourType(null);
        setIsModalVisible(true);
    };

    const showEditModal = (hourType) => {
        setEditingHourType(hourType);
        setIsModalVisible(true);
    };

    const handleDelete = (id) => {
        SevenShiftApi.deleteHourType(
            id,
            (response) => {
                notification.success({ message: "Hour Type deleted successfully" });
                fetchHourTypes();
            },
            (error) => {
                notification.error({ message: "Failed to delete hour type", description: error.response?.data?.message || error.message });
            }
        );
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setEditingHourType(null);
    };

    const handleFormSubmit = (values) => {
        if (editingHourType) {
            // Update existing hour type
            SevenShiftApi.updateHourType(
                editingHourType.id,
                values,
                (response) => {
                    notification.success({ message: "Hour Type updated successfully" });
                    handleModalClose();
                    fetchHourTypes();
                },
                (error) => {
                    notification.error({ message: "Failed to update hour type", description: error.response?.data?.message || error.message });
                }
            );
        } else {
            // Create new hour type
            SevenShiftApi.createHourType(
                values,
                (response) => {
                    notification.success({ message: "Hour Type created successfully" });
                    handleModalClose();
                    fetchHourTypes();
                },
                (error) => {
                    notification.error({ message: "Failed to create hour type", description: error.response?.data?.message || error.message });
                }
            );
        }
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Space size="middle">
                    <Button icon={<EditOutlined />} onClick={() => showEditModal(record)}>
                        Edit
                    </Button>
                    <Popconfirm
                        title="Are you sure to delete this hour type?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button icon={<DeleteOutlined />} danger>
                            Delete
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <DefaultContainer>
            <Button type="primary" icon={<PlusOutlined />} onClick={showAddModal} style={{ marginBottom: 16 }}>
                Add Hour Type
            </Button>
            <Table
                dataSource={hourTypes}
                columns={columns}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 10 }}
            />

            <Modal
                title={editingHourType ? "Edit Hour Type" : "Add Hour Type"}
                visible={isModalVisible}
                footer={null}
                onCancel={handleModalClose}
                destroyOnClose
            >
                <HourTypeForm
                    initialValues={editingHourType}
                    onSubmit={handleFormSubmit}
                    onCancel={handleModalClose}
                />
            </Modal>
        </DefaultContainer>
    );

};

export default HourTypesList;
