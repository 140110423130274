import React from 'react';
import { Select, Tooltip, Form } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const { Item: FormItem } = Form;

const StatusDropdown = ({ isDiscountRequester, editCouponGeneratePreset, form }) => {
    return (
        !isDiscountRequester && (
            <div className="flex flex-row items-start justify-start w-full mb-4">
                <label htmlFor="status" className="mr-2 pt-1 whitespace-nowrap w-72 text-right">
                    Status
                    <Tooltip title="Specify the status of the discount.">
                        <FontAwesomeIcon icon={faInfoCircle} className="ml-1 text-gray-500"/>
                    </Tooltip>
                </label>
                <FormItem
                    name="status"
                    rules={[
                        { required: true, message: 'Please select a status.' },
                        { type: 'number', enum: [-1, 0, 1], message: 'Invalid status selected.' }
                    ]}
                >
                    <Select
                        placeholder="Select status"
                        defaultValue={editCouponGeneratePreset?.status}
                        onChange={(value) => form.setFieldsValue({ status: value })}
                        style={{ width: 200 }}
                    >
                        <Select.Option value={-1}>Pending</Select.Option>
                        <Select.Option value={0}>Disabled</Select.Option>
                        <Select.Option value={1}>Active</Select.Option>
                    </Select>
                </FormItem>
            </div>
        )
    );
};

export default StatusDropdown;
