// src/components/PayPeriods/PayPeriodsList.js

import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Space, notification } from 'antd';
import { PlusOutlined, RightOutlined } from '@ant-design/icons';
import PayPeriodForm from './PayPeriodForm';
import SevenShiftApi from '../../../../../http/SevenShiftApi';
import DefaultContainer from "../../../../../components/DefaultContainer";
import { useHistory } from 'react-router-dom'; // Import useHistory for navigation

const PayPeriodsList = () => {
    const [payPeriods, setPayPeriods] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingPayPeriod, setEditingPayPeriod] = useState(null);
    const history = useHistory(); // Initialize useHistory

    const fetchPayPeriods = () => {
        setLoading(true);
        SevenShiftApi.getPayPeriods(
            (response) => {
                setPayPeriods(response.data.data);
                setLoading(false);
            },
            (error) => {
                setLoading(false);
                notification.error({ message: "Failed to fetch pay periods", description: error.response?.data?.message || error.message });
            }
        );
    };

    useEffect(() => {
        fetchPayPeriods();
    }, []);

    const showAddModal = () => {
        setEditingPayPeriod(null);
        setIsModalVisible(true);
    };

    const handleDelete = (id) => {
        // Remove Delete functionality as per requirements
        // Previously present delete logic is removed
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setEditingPayPeriod(null);
    };


    const handleProcess = (payPeriodId) => {
        // Navigate to the processing page for the selected pay period
        history.push(`/dashboard/payrolls/pay_period/${payPeriodId}/process`);
    };

    const columns = [
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
            render: (date) => new Date(date).toLocaleDateString(),
        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            key: 'end_date',
            render: (date) => new Date(date).toLocaleDateString(),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => status.charAt(0).toUpperCase() + status.slice(1),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="primary"
                        icon={<RightOutlined />}
                        onClick={() => handleProcess(record.id)}
                    >
                        Process
                    </Button>
                </Space>
            ),
        },
    ];

    return (
        <DefaultContainer>

            <Table
                dataSource={payPeriods}
                columns={columns}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 10 }}
            />

        </DefaultContainer>
    );
};

export default PayPeriodsList;
