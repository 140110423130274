import axios from 'axios';
import { checkLoggedIn, getAuthenticationHeaders, getAuthenticationHeadersMultiPart } from './Headers';

const SevenShiftApi = {

    // Existing Sync Methods

    // Sync Users
    syncUsers: (successCb, failCb) => {
        axios.post(`/seven-shifts/sync-users`, {}, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // General Sync
    sync: (successCb, failCb) => {
        axios.post(`/seven-shifts/sync`, {}, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Hour Types Management

    // Fetch all Hour Types
    getHourTypes: (successCb, failCb) => {
        axios.get(`/hour-types`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Create a new Hour Type
    createHourType: (data, successCb, failCb) => {
        axios.post(`/hour-types`, data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Update an existing Hour Type
    updateHourType: (id, data, successCb, failCb) => {
        axios.put(`/hour-types/${id}`, data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Delete an Hour Type
    deleteHourType: (id, successCb, failCb) => {
        axios.delete(`/hour-types/${id}`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Hour Type Reports

    // Generate report for a specific user
    getHourTypeReportForUser: (userId, startDate, endDate, successCb, failCb) => {
        axios.get(`/hour-type-reports/user/${userId}`, {
            headers: {
                ...getAuthenticationHeaders().headers,
            },
            params: {
                start_date: startDate,
                end_date: endDate,
            }
        })
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Generate report for all users
    getHourTypeReportForAll: (startDate, endDate, successCb, failCb) => {
        axios.get(`/hour-type-reports/all`, {
            headers: {
                ...getAuthenticationHeaders().headers,
            },
            params: {
                start_date: startDate,
                end_date: endDate,
            }
        })
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Fetch all users (for reports)
    getUsers: (successCb, failCb) => {
        axios.get(`/users`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Fetch Roles for Hour Types
    getHourTypeRoles: (successCb, failCb) => {
        axios.get(`/hour-types/roles`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Benefits Management

    // Fetch all Benefits
    getBenefits: (successCb, failCb) => {
        axios.get(`/benefits`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Create a new Benefit
    createBenefit: (data, successCb, failCb) => {
        axios.post(`/benefits`, data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Update an existing Benefit
    updateBenefit: (id, data, successCb, failCb) => {
        axios.put(`/benefits/${id}`, data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Delete a Benefit
    deleteBenefit: (id, successCb, failCb) => {
        axios.delete(`/benefits/${id}`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Fetch Roles for Benefits
    getBenefitRoles: (successCb, failCb) => {
        axios.get(`/benefits/roles`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Fetch Users for Benefit Exceptions
    getUsersForBenefitExceptions: (successCb, failCb) => {
        axios.get(`/benefits/users-for-exceptions`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    getRoles: (successCb, failCb) => {
        axios.get(`/hour-types/roles`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Fetch all Pay Periods
    getPayPeriods: (successCb, failCb) => {
        axios.get(`/pay-periods`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Create a new Pay Period
    createPayPeriod: (data, successCb, failCb) => {
        axios.post(`/pay-periods`, data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Update an existing Pay Period
    updatePayPeriod: (id, data, successCb, failCb) => {
        axios.put(`/pay-periods/${id}`, data, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    // Delete a Pay Period
    deletePayPeriod: (id, successCb, failCb) => {
        axios.delete(`/pay-periods/${id}`, getAuthenticationHeaders())
            .then(response => {
                successCb(response);
            })
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    processPayPeriod: (id, successCb, failCb) => {
        axios.post(`/pay-periods/${id}/process`, {}, getAuthenticationHeaders())
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },

    exportPayPeriodCSV: (id, successCb, failCb) => {
        axios.get(`/pay-periods/${id}/export-csv`, {
            headers: {
                ...getAuthenticationHeaders().headers,
            },
            responseType: 'blob',
        })
            .then(response => successCb(response))
            .catch(err => {
                checkLoggedIn(err);
                failCb(err);
            });
    },
}

export default SevenShiftApi;
