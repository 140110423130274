import React, { useEffect, useState, useCallback } from 'react';
import { useParams, Link, useLocation, useHistory } from 'react-router-dom';
import { Button, notification, Pagination } from 'antd';
import Table from 'ant-responsive-table';
import BranchInventory from '../../../../../http/BranchInventory';
import SkeletonWrapper from '../../../../../components/skeletons/SkeletonWrapper';
import GenericSkeleton from '../../../../../components/skeletons/GenericSkeleton';
import DefaultContainer from '../../../../../components/DefaultContainer';
import moment from 'moment';
import Styled from 'styled-components';

// Optional: Styled component to adjust table styles if needed
const ContainerWrapper = Styled.div`
    .ant-card-body div {
        white-space: nowrap;
        width: auto !important;
    }
`;

const EndOfShiftReportsIndex = () => {
    const { branchId } = useParams();
    const location = useLocation();
    const history = useHistory();

    // Utility to parse query parameters
    const getQueryParams = useCallback(() => {
        const params = new URLSearchParams(location.search);
        return {
            page: parseInt(params.get('page'), 10) || 1,
            pageSize: parseInt(params.get('pageSize'), 10) || 5,
            sortField: params.get('sortField') || 'created_at',
            sortOrder: params.get('sortOrder') || 'desc',
        };
    }, [location.search]);

    // Initialize state based on URL query parameters
    const initialParams = getQueryParams();

    const [pagination, setPagination] = useState({
        current: initialParams.page,
        pageSize: initialParams.pageSize,
        total: 0,
    });

    const [sorter, setSorter] = useState({
        field: initialParams.sortField,
        order: initialParams.sortOrder,
    });

    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(false);
    const [reports, setReports] = useState([]);

    // Effect to synchronize state with URL changes
    useEffect(() => {
        const { page, pageSize, sortField, sortOrder } = getQueryParams();

        // Check if the URL parameters differ from the current state
        const isDifferent =
            page !== pagination.current ||
            pageSize !== pagination.pageSize ||
            sortField !== sorter.field ||
            sortOrder !== sorter.order;

        if (isDifferent) {
            setPagination((prev) => ({
                ...prev,
                current: page,
                pageSize: pageSize,
            }));
            setSorter({
                field: sortField,
                order: sortOrder,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    // Effect to load reports whenever pagination or sorter changes
    useEffect(() => {
        loadReports();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination.current, pagination.pageSize, sorter.field, sorter.order, branchId]);

    const loadReports = () => {
        // Determine if it's an initial load based on the current state
        const isInitialLoad =
            pagination.current === initialParams.page &&
            pagination.pageSize === initialParams.pageSize &&
            sorter.field === initialParams.sortField &&
            sorter.order === initialParams.sortOrder;

        if (isInitialLoad) {
            setLoading(true);
        } else {
            setTableLoading(true);
        }

        BranchInventory.IndexEndOfShiftReports(
            branchId,
            {
                params: {
                    per_page: pagination.pageSize,
                    page: pagination.current,
                    sort_by: sorter.field,
                    sort_direction: sorter.order === 'ascend' ? 'asc' : 'desc',
                },
            },
            (response) => {
                if (response && response.data && response.data.data) {
                    const { data } = response.data;
                    // Preprocess the reports data
                    const processedReports = data.reports.data.map((record) => {
                        let noticesInfo = 'None';
                        if (record.notices_given) {
                            // If notices_given is true, display the type and assigned user
                            const assignedUserName = record.notice_user ? record.notice_user.name : 'Unknown User';
                            noticesInfo = `${record.notice_type || 'Notice/Warning'} given to ${assignedUserName}`;
                        }

                        return {
                            ...record,
                            userName: record.user?.name || '-',
                            createdAtFormatted: moment(record.created_at).format('DD/MM/YYYY HH:mm'),
                            noticesInfo: noticesInfo,
                            actions: (
                                <Link to={`/dashboard/operations/daily-logs/end-of-shift/report/${record.id}`}>
                                    <Button type="link">View Report</Button>
                                </Link>
                            ),
                        };
                    });

                    setReports(processedReports);
                    setPagination((prev) => ({
                        ...prev,
                        total: data.reports.total,
                    }));
                } else {
                    notification.error({
                        message: 'Error!',
                        description: 'Unexpected response format while loading end of shift reports.',
                    });
                }
                setLoading(false);
                setTableLoading(false);
            },
            (err) => {
                notification.error({
                    message: 'Error!',
                    description: err.response?.data?.message || 'Failed to load end of shift reports.',
                });
                setLoading(false);
                setTableLoading(false);
            }
        );
    };

    // Function to update the URL with new query parameters
    const updateURL = (newParams) => {
        const params = new URLSearchParams(location.search);

        Object.keys(newParams).forEach((key) => {
            if (newParams[key] !== undefined && newParams[key] !== null) {
                params.set(key, newParams[key]);
            } else {
                params.delete(key);
            }
        });

        history.push({
            pathname: location.pathname,
            search: params.toString(),
        });
    };

    // Handler for table changes (pagination, sorting)
    const handleTableChange = (paginationConfig, filters, sorterConfig) => {
        const newPagination = {
            current: paginationConfig.current,
            pageSize: paginationConfig.pageSize,
            total: pagination.total,
        };

        const newSorter = {
            field: sorterConfig.field || sorter.field,
            order: sorterConfig.order || sorter.order,
        };

        setPagination(newPagination);
        setSorter(newSorter);

        // Update the URL with new pagination and sorting parameters
        updateURL({
            page: newPagination.current,
            pageSize: newPagination.pageSize,
            sortField: newSorter.field,
            sortOrder: newSorter.order,
        });
    };

    // Handlers for Pagination component
    const handlePageChange = (page, pageSize) => {
        setPagination((prev) => ({
            ...prev,
            current: page,
            pageSize: pageSize,
        }));

        // Update the URL
        updateURL({
            page: page,
            pageSize: pageSize,
            sortField: sorter.field,
            sortOrder: sorter.order,
        });
    };

    const handlePerPageChange = (current, size) => {
        setPagination((prev) => ({
            ...prev,
            pageSize: size,
            current: 1, // Reset to first page when changing page size
        }));

        // Update the URL
        updateURL({
            page: 1,
            pageSize: size,
            sortField: sorter.field,
            sortOrder: sorter.order,
        });
    };

    // Define columns for the responsive table without render functions
    const columns = [
        {
            title: 'User',
            dataIndex: 'userName',
            key: 'userName',
            sorter: true,
            sortOrder: sorter.field === 'user.name' && sorter.order,
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Date of Report',
            dataIndex: 'date',
            key: 'date',
            sorter: true,
            sortOrder: sorter.field === 'date' && sorter.order,
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Submitted At',
            dataIndex: 'createdAtFormatted',
            key: 'createdAtFormatted',
            sorter: true,
            sortOrder: sorter.field === 'created_at' && sorter.order,
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Shift',
            dataIndex: 'shift',
            key: 'shift',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Notices/Warnings',
            dataIndex: 'noticesInfo',
            key: 'noticesInfo',
            showOnResponse: true,
            showOnDesktop: true,
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            showOnResponse: true,
            showOnDesktop: true,
        },
    ];


    return (
        <ContainerWrapper>
            <SkeletonWrapper loading={loading} skeleton={GenericSkeleton}>
                <DefaultContainer>
                    <h2>End of Shift Reports</h2>
                    <Table
                        antTableProps={{
                            dataSource: reports,
                            columns: columns,
                            pagination: false, // Disable internal pagination
                            rowKey: 'id',
                            onChange: handleTableChange,
                        }}
                        columns={columns}
                        dataSource={reports}
                        rowKey="id"
                        pagination={false} // Disable internal pagination
                        mobileBreakPoint={1200} // Adjust as needed
                        loading={tableLoading}
                    />
                    <div className="mt-4 ml-4 w-full">
                        <Pagination
                            current={pagination.current}
                            pageSize={pagination.pageSize}
                            total={pagination.total}
                            onChange={handlePageChange}
                            onShowSizeChange={handlePerPageChange}
                            showSizeChanger
                            pageSizeOptions={['5', '10', '20']}
                            showTotal={(total) => `Total ${total} items`}
                            className={"w-full"}
                        />
                    </div>
                </DefaultContainer>
            </SkeletonWrapper>
        </ContainerWrapper>
    );
};

export default EndOfShiftReportsIndex;
